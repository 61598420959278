<template>
    <router-link class="position-relative list-group-item list-group-item-action shadow-sm p-0"
                 v-bind:key="activityId"
                 v-bind:to="`/events/${ activityId }`">
        <div class="position-absolute status-bar shadow-sm"
             v-bind:style="{ 'background-color': color }">
        </div>
        <div class="px-4 py-2">
            <p class="mb-0">
            <span class="badge rounded-pill bg-info text-white px-2 mr-1 mb-1"
                  v-if="isFree">
                Free
            </span>
                <span class="badge rounded-pill bg-warning text-black px-2 mr-1 mb-1"
                      v-if="isPromoted">
                Featured
            </span>
            </p>
            <p class="font-weight-bolder text-uppercase mb-1">
                {{ startDate }}{{ startDate !== endDate ? ` - ${ endDate }` : "" }}
            </p>
            <h5 class="font-weight-bold mb-1">
                {{ title }}
            </h5>
            <p class="mb-0">
                {{ startTime }} - {{ endTime }}
            </p>
        </div>
        <div class="bg-light text-right px-2 py-1">
            <button class="btn btn-sm btn-success shadow-sm rounded-pill mr-1"
                    v-if="status === 'pending' || status === 'rejected'"
                    v-bind:class="{ 'btn-loading': isLoading }"
                    v-on:click.stop.prevent="approve">
                👍Approve
            </button>
            <button class="btn btn-sm btn-danger shadow-sm rounded-pill"
                    v-if="status === 'pending' || status === 'approved'"
                    v-bind:class="{ 'btn-loading': isLoading }"
                    v-on:click.stop.prevent="reject">
                👎Reject
            </button>
        </div>
    </router-link>
</template>

<script>
import format from "date-fns/format";

const colorMap = new Map([
    ["approved", "#2dd36f"],
    ["pending", "#ffc409"],
    ["rejected", "#eb445a"]
])

export default {
    name: "ActivityItem",
    props: {
        activity: {
            type: Object,
        },
    },
    data () {
        return {
            isLoading: false,
        };
    },
    computed: {
        activityId () {
            return this.activity?.eventId ?? "";
        },
        title () {
            return this.activity?.title ?? "";
        },
        start () {
            return this.activity?.start ?? null;
        },
        end () {
            return this.activity?.end ?? null;
        },
        startDate () {
            return this.start ? format(this.start, "dd MMM") : "";
        },
        startTime () {
            return format(this.start, "p");
        },
        endDate () {
            return this.end ? format(this.end, "dd MMM") : "";
        },
        endTime () {
            return format(this.end, "p");
        },
        status () {
            return this.activity.status;
        },
        color () {
            return colorMap.get(this.status);
        },
        isFree () {
            return this.activity.isFree;
        },
        isPromoted () {
            return this.activity.isPromoted;
        },
        isFull () {
            return this.activity.isFull;
        },
    },
    methods: {
        async approve () {
            try {
                this.isLoading = true;
                await this.$store.dispatch(
                    "event/editEvent",
                    {
                        "_id": this.activityId,
                        "status": "approved",
                    }
                );
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async reject () {
            try {
                this.isLoading = true;
                await this.$store.dispatch(
                    "event/editEvent",
                    {
                        "_id": this.activityId,
                        "status": "rejected",
                    }
                );
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.status-bar {
    width: 8px;

    top: 0;
    left: 0;
    bottom: 0;
}
</style>
